*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: sans-serif;
}

.img-logo
{
    border-radius: 50px;
}
.back-btn
{
    cursor: pointer;
    position: absolute;
}
.delete-dir-style{
    color: #662626;
    cursor: pointer;
    margin-left: 10%;
}
.header-container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
display: contents;
}
.header-logo{
    margin-left: 3%;
    margin-top: 0.75%;

}
.header-nav{
    background-color: #febe10;
    height: 65px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: +3;
}
label.logo{
    color:#fff;
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
}
.header-nav .header-ul{
    float: right;
    margin-right: 20px;
}
.header-nav .header-ul .header-list{
    display: inline-block;
    line-height: 70px;
    margin:0 20px;
    color:#fff;
font-size: 17px;
font-weight: 700;
cursor: pointer;
}
.ul-link{
color:#fff;
font-size: 17px;
}
.checkbtn{
    font-size: 30px;
    color: #fff;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}
.LogoName
{
    position: fixed;
    color: #fff;
    font-weight: 600;
    font-size: 4vh;
    margin-top: 0%;
}
.donatebtn{
    color: black;
    font-size:17px;
    font-family: sans-serif;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    padding: 10px;
    color: #febe10
}

.header-flex
{
    display: flex;
    justify-content: space-evenly;
}
.donatesymbol{
    width: 16%;
    margin-left: 0%;
    position: relative;
}
.active{
    text-decoration: underline;
}
@media (max-width: 952px) {
    .card-school {
        text-align: center;
        font-size: 20px;
        text-align-last: start;
    }
    label.logo{
        font-size: 30px;
        padding-left: 50px;
    }
    .ul-link{
        font-size: 16px;
    }
}
@media (max-width: 858px) {
 .checkbtn{
     display: block;
 }
 .header-ul{
     position: fixed;
     width: 100%;
     height: 100vh;
     background-color: #febe10;
     top: 60px;
     left: -100%;
     text-align: center;
     transition: all .5s;
     z-index: +1;
 }
 .header-nav .header-ul .header-list{
     display: block;
     text-decoration: none;
 }
 .ul-link{
     font-size: 20px;
    }
    #check:checked ~ .header-ul{
        left: 0;
        z-index: +1;
    }
    .logout{
        display: none;
    }
    .logout2{
        display: block;
    }
    .header-logo{
        margin-left: 3%;
        margin-top: 3%;
    
    }
}
@media only screen and (max-width: 600px) {
    .donatesymbol{
        width: 20px;
        margin-left: 0%;
        position: relative;
    }
  }