.donate-part{
    margin-top: 18%;
    margin-bottom: 16%;
}
.dtn-btn{
    margin: 2%;
}
.eighteeg-btn{
  margin: 10%;
}

.modal__backdrop {
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .modal__head{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .modal__close{
      cursor: pointer;
  }
  .modal__container {
         background:#fff;
    border-radius: 5px;
    max-width: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 250px;

  }
  
  .modaloption
  {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 15%;
      text-align: left;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #febe10;
}

@media only screen and (max-width: 600px) {
    .donate-part{
        margin-top: 50%;
        margin-bottom: 65%;
    }
  }