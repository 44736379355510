.Footer-body{
    background-color: #febe10;
    height: 75px;
}
.footer-text{
    text-align: center;
    width: 100%;
    padding-top: 1.5%;
    font-size: 15px;
    font-weight: 400;
}
.footer-contact
{
    font-size: 15px;
    font-weight: 700;
}
.email-yellow
{
    color: #febe10;
}
.foot{
    color: #000;
}
.foot:hover{
    color: #000;
    text-decoration: none;
}
.footer-contact-text{
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
}
.social-links a {
    font-size: 18px;
    display: inline-block;
    background: #ffc107;
    color: #fff;
    line-height: 1;
    padding: 10px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
.social-links{
    margin: 10px;
}