.futurepart{
  margin-top: 1%;
  margin-bottom: -0.9%;
}

.card-school{
text-align: center;
font-size: 20px;
}
.futuretext{
  text-align: center;
  font-size: 26px;
}
.contribute-text{
  text-align: center;
  font-size: 26px;
  color: #fff;
}
.eventname{
  font-size: 21px;
}
.eventloc{
  font-size: 18px;
}
.eventimg{
  width: 8%;
}
.futurecard
{
  width: 100%;
}  
:root {
  --surface-color: #fff;
  --curve: 40;
}
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 2% 2%;
  padding: 0;
  width: 75%;
  margin-left: 15%;
  list-style-type: none;
}
.card {
  position: relative;
  display: block;
  height: 350px;  
  width: 300px;
  border-radius: calc(var(--curve) * 0.4px);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  text-decoration: none;
}
.card__image {      
  width: 300px;
  height: 280px;
}
.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}
.card:hover .card__overlay {
  transform: translateY(0);
}
.card__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 0 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out; 
}
.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 19px;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;

  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: 17px;
  color: #473b42;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}    
.donatebtn2{
  font-size:15px;
  font-family: sans-serif;
  border-radius: 20px;
  background-color: #febe10;
  cursor: pointer;
  padding: 8px;
  color: #000;
  border:1px solid #fff
}
.bottomcard
{
display: flex;
flex-direction: row;
justify-content:center;
width: 100%;
}
.img-card{
  min-width: none;
  display: flex;
  flex-direction: column;
}
.responsive {
  width: 300px;
  height: 200px;
  display: block;
}

/* Contributors Part */

.bg-img{
  background-image: linear-gradient(rgba(19, 4, 4, 0.842), #e6ae134f), url('../Images/contributors_background.png');;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 460px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}


.Contributors-container{
  /* background-image: linear-gradient(rgba(19, 4, 4, 0.842), #e6ad1373), url('../Images/contributors_background.png'); */
  background-size: 100%;
  background-size: cover;
  padding: 1%;
  margin: 0% 0% 2% 0%;
  
}
.Contributors {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: static;
  margin-bottom: -1px;
  border-radius: 50px 20px;
}
.Contributors .Contributors-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 350px;
  margin-bottom:50px;
}
.Contributors .Contributors-slide img {
  display: block;
  width: 300px;
  height: 300px;
}
.Contributors-name{
  justify-content: center;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}
.Contributors-desc{
  text-align: center;
  font-size: 15px;
}
.swipe-card {
  position: relative;
  display: block;
  height: 385px;  
  width: 300px;
  overflow: hidden;
  text-decoration: none;
  background-color: #fff
}
/* swiper */
.Starting-swiper {
  width: 100%;
  height: 100%;
}
.Starting-swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  transition:background-color 0.5s;
  align-items: center;
  
}

.Starting-swiper-slide .Starting-img {
  width:1550px;
  height: 500px;
}
.Starting-carousel
{
  margin-top: 4.2%;
}
.Starting-text-container{
 display: flex;
 justify-content: center;
}
.Starting-text{
  position: absolute;
  color: #ffffcc;
  top: 0;
  background-color:rgba(0, 0, 0, 0.5);
  width: 1600px;
  height: 80px;
  font-size: 4vh;
  font-weight: 500;
  padding: 1%;
}

  /*  */
  .countpart
  {
    margin: 2% 0% 0% 0%;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffcc;
  }
 
  .countpart-text
  {
    font-size: 26px;
    font-weight: 500;
  }
  .countpart-names
  {
    font-size: 16px;
    font-weight: bold;  
    text-transform: uppercase;
  }

.countpart-layer
{
  padding: 30px 0px;
  background-color: #ffffcc;
}
  .countpart-container
{
 display: flex; 
 justify-content: center;
 background-size: cover;
flex-direction: row;
}
.countpart-card
{
  margin: 10px;
  padding: 10px 60px;
  border-right: 1px solid rgba(141, 126, 126, 0.397);
  font-size: 30px;
  color: rgba(48, 7, 7, 0.822);
  text-align: center;
}

.Difference-carousel-container{
  margin: 2% 5%;
}
.Difference-carousel
{
padding: 1% 0%;
margin-left: 4%;
}
.responsive2 {
  width: 400px;
  height: 350px;
}
.responsive2:hover {
  transform: scale(1.2);
  transition: 0.2s;
}
.new-card {
  position: relative;
  display: block;
  width: 380px;
  gap:2px;
  overflow: hidden;
  text-decoration: none;
}
.count-image{
padding-right: 10px;
width: 50px;
}
.part-container{
  margin: 2% 5%;
}
.part-box{
  padding: 2% 8%;
}
.part-responsive{
  width: 500px;
  height: 300px;
  border-radius: 5px;
}
.part-column{
  display: flex;
  flex-direction: row;
}
.part-text-container{
  margin: 6%;
}
.part-btn{
  margin-top: 10%;
}


@media only screen and (max-width: 900px) {
  .futuretext{
    text-align: center;
    font-size: 24px;
  }
.Difference-carousel
{
  margin-left: 0%;
}
  .part-column{
    display: flex;
    flex-direction: column;
  } 
  .part-responsive{
    width: 100%;
    height: 200px;
    border-radius: 5px;
  } 
  .part-container{
    margin: 0% 0%;
  }
    .Starting-carousel
    {
      margin-top: 18%;
    }
    .Starting-swiper-slide .Starting-img {
      width:100%;
      height: 200px;
    }
    .Starting-text{
      position: absolute;
      color: #fff;
      top: 0;
      background-color:rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 50px;
      font-size: 2vh;
      font-weight: 500;
      padding: 4.5%;
    }
      .cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 6rem;
        margin: 4rem 8vw;
        padding: 0;
        list-style-type: none;
      }
      .futurepart{
        margin-top: 10%;
        padding-left: 0%;
        padding-right: 0%;
        margin-bottom: 10%;
      }
      .bottomcard
      {
      display: flex;
      flex-direction: row;
      justify-content:flex-end;
      width: 100%;
      margin-left: 0%;
      }
      
        .countpart-container
        {
         display: flex; 
         justify-content: center;
         background-size: cover;
        flex-direction: column;
        }
        .countpart-card
      {
        /* margin: 10px;
        padding: 20px; */
        font-size: 10px;
        padding: 20px;
        border-right: 0px solid rgba(141, 126, 126, 0.397);
        width: 95%;
        
      }
      .countpart-text
      {
        font-size: 25px;
        font-weight: 500;
      }
      .countpart-names
  {
    text-align: center;
    font-size: 16px;
    font-weight: bold;  
    text-transform: uppercase;
  }
      .Difference-carousel
      {
        margin-left: 0%;
      }
      }
      