#sameera{
    width:280px;
    height: 250px;
    border-radius: 5px;    
 }

.dif-container{
    padding: 0% 13%;
    margin-bottom: 17%;
}
 .dif-name{
    font-size: 18px;
    padding-left: 80px;
    color: black;
}

.differentiator-card {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .diff-card1 {
    margin: 30px auto;
    margin-top: 5%;
    width: 300px;
    height: 300px;
    cursor: pointer;
    transition: 0.4s;
  }
  
  .diff-card1 .diff-cardimage {
    width: inherit;
    height: inherit;
  }
  
  .diff-card1 .diff-cardimage img {
    display: block;
    width: 300px;
    height: 300px;
  }
  
  .diff-card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -80px;
    height: 40px;
  }
  
  .title-white {
    color: white;
  }
  
  .title-black {
    color: black;
  }

  .middle {
  background-color: rgba(238, 232, 224, 0.705);
  height: 65px;
  text-align: center;
  border: 1px solid rgb(190, 187, 187);
  }

  .diffcard-text{
  padding: 5%;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  }

  @media all and (max-width: 500px) {
    .card-list {
      flex-direction: column;
    }
  }