.Journey-container{
    margin: 7% 0% 14%;
}
.grid-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 50px));
  grid-gap: 10px;
  justify-content: center;
  align-items: baseline;
}
.grid-row .grid-item {
  transition: filter 0.5s ease;
  display: flex;
  text-align: center;
  align-items: stretch;
}

.main_textcard{
  background-color: black;
width: 100px;
}
.Journey-box-district{
  padding: 0% 2% 0% 2%;
  margin: 0% 10% 2% 10%;
}
.Journey-box{
  padding: 2%;
  margin: 5% 10% 0.5% 10%;
  border: 1px solid rgba(156, 155, 155, 0.452);
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.grid-contain{
  z-index: 0;
  width: 110%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.event-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.event-container{
  margin: 3% 10%;
  width: 100%;
  
}
.close:hover{
  color: #febe10;
}
.event:hover{
  transform: scale(0.9, 0.9);
  background-color: #febe10;
  color: #fff;
}
.optionyes:hover{
  transform: scale(0.9, 0.9);
  background-color: #febe10;
  color: #fff;
}

.event {
  margin: 10px 10px;
  width: 250px;
  height: 50px;
  border: 1px solid #cecdc983;
  cursor: pointer;
  transition: 0.4s;
  text-align: center;
  padding-top: 1%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.optionyes{
  margin: 10px 10px;
  width: 90%;
  height: 50px;
  border: 1px solid #cecdc983;
  cursor: pointer;
  transition: 0.4s;
  text-align: center;
  padding: 5%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.event .event_title {
  justify-content: center;
  padding: 5px;
  margin-top: 2%;
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  color: #000;
  word-wrap: break-word;
}

.grid{
  width: 10px;
  height: 30px;
  border: 1px solid #cecdc983;
  cursor: pointer;
  transition: 0.4s;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.grid:hover{
  background-color: #febe10;
  color: #ffffff;
}
.card-img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  max-width:100%;
  width: 300px;
  height: 240px;
 }
  
 .card-title {
  margin-bottom: 0.3rem;
  color: #febe10;
  font-weight: 100;
}

  
  .cat {
    display: inline-block;
    margin-bottom: 1rem;
  }
  
  .fa-users {
    margin-left: 1rem;
  }
  
  .card-footer {
    font-size: 0.8rem;
  } 

  .cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;  
  }
  
  .textcard {
    margin: 15px;
    width: 200px;
    height: auto;
    padding: 0.5%;
    cursor: pointer;
    text-align: center;
    transition: 0.2s;
    border: 1px solid rgb(194, 188, 188);
    position: static;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.43);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4px);
-webkit-backdrop-filter: blur(4px);
  }
  .textcard .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    padding: 10%;
    margin-top: -80px;
    height: 40px;
  }
  
  .textcard:hover {
    transform: scale(0.9, 0.9);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  
  .journeycard {
    margin: 15px auto;
    width: 300px;
    height: 380px;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  }
  
  .journeycard .card_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
  }
  
  .journeycard .card_image img {
    width: inherit;
    height: inherit;
    border-radius: 40px;
    object-fit: cover;
  }
  
  .journeycard .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -80px;
    height: 40px;
  }
  
  .journeycard:hover {
    transform: scale(0.9, 0.9);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .title-white {
    color: white;
  }
  
  .title-black {
    color: black;
  }
  .journey-date {
    height: 60px;
    width: 60px;
    background-color: #febe10;
    position: absolute;
    right: -0.5%;
    bottom: 41%;
   }
   .journey-dat{
    font-size: 18px;
    color: #ffffff;
    text-align: center;
  }
  .demo-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .active-grid{
    background-color: #febe10;
    color: #fff;
  }
  .Loading{
    margin: 10%;
  }
  .modal__backdrop {
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    text-align: center;
    z-index: 1;
  }
  .modal__container {
    background: #fff;
    border-radius: 5px;
    max-width: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 290px;
  }
  .modaloption
  {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-evenly;
      margin-top: 15%;
  }
  .header-modal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .close{
    cursor: pointer;
  }

  .seemore-option-IV {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .option-IV {
    margin: 10px 10px;
    width: 120px;
    height: 40px;
    border: 1px solid #cecdc983;
    cursor: pointer;
    transition: 0.4s;
    text-align: center;
    padding-top: 1%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }
  .option-IV:hover{
    transform: scale(0.9, 0.9);
    background-color: #febe10;
    color: #fff;
  }
 
  @media all and (max-width: 500px) {
    .card-list {
      flex-direction: column;
    }
    
  }

    @media all and (max-width: 900px) {
      .grid-row {
        display: grid;
        grid-template-columns: none;
        grid-gap: 15px 15px;
        justify-content: center;
        align-items: baseline;
    }
        .Journey-container{
            margin: 25% 0%;
        }
        .journey-date {
          height: 60px;
          width: 60px;
          background-color: #febe10;
          margin-top: -61px;
          margin-left: 219px;
        }
        .journey-dat{
          font-size: 18px;
          color: #ffffff;
          text-align: center;
        
        }
  }