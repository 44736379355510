.seemore-container{
  margin: 6% 3%;
  display: flex;
  justify-content: center;
  }
  .box-three:hover{
    transform: scale(0.9, 0.9);
    background-color: #febe10;
    color: #fff;
  } 
  .box-three
  {
    width:300px;
    height:50px;
    margin: 30px;
    background-color: #fff;
    border: 1px solid #cecdc983;
    cursor: pointer;
    transition: 0.4s;
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  }
  
  .box-three p
  {
    margin: 0px;
    position: relative;
    top: 50%;
    transform: translate(0,-50%);
    text-align: center;
  }

  
  .skeleton-contain{
    display: flex;
    flex-direction: row;
  }
  .skeleton-col{
    margin-left: 10%;
  }
  .skelet{
    margin-bottom: 10%;
  }
  .backoption{
    cursor: pointer;
    width: 8%;
  }
  .width-height
  {
    width: 500px;
  }
  .seemore-skeleton{
margin: 2% 2% 20% 2%;
  }
  .seemore-text-skeleton{
    margin: 20% 0% 2% 0%;
  }
  .optiongrid{
    margin-right: -10%;
  }
  .option:hover{
    transform: scale(0.9, 0.9);
    background-color: #febe10;
    color: #fff;
  } 
  .tex{
    position: relative;
    top:30%
   }
  .option{
    margin: 10%;
    width: 100%;
    height: 10vh;
    padding: 0% 10%;
    font-size: 15px;
    border: 1px solid #cecdc983;
    cursor: pointer;
    transition: 0.4s;
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
}
.seemore-option2:hover{
  transform: scale(0.9, 0.9);
  background-color: #febe10;
  color: #fff;
}

.seemore-option2{
  margin: 50% 50%;
  height: auto;
  border: 1px solid #cecdc983;
  cursor: pointer;
  transition: 0.4s;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  width: 100px;
  padding: 10px;
  text-align: center;
}
  .social-share-container{
    display: flex;
  flex-direction: column;
  font-size: 30px;
  margin-top: 10%;
  text-align: center;
  }
  .facebook{
    color: #1877f2;
    cursor: pointer;
  }
  .urlcopy{
    cursor: pointer;
  }
  .whatsapp{
    color: #6cc751;
    cursor: pointer;
  }
  .twitter{
    color: #1da1f2;
    cursor: pointer;
  }
  .year-wise{
    margin: 50%;
  }
  .decide-flex{
      display: flex;
      width: 25%;
      justify-content: space-between;
  }
  .seemore-img
  {   
  width: 100%;
  height: 500px;
  }
  .error-comment-input{
  border: 1px solid red;
  }
  .seemore-comment-btn
  {
  width: 60%;
  padding: 2%;
  border: 1px solid #fff;
  background-color: rgba(223, 217, 217, 0.466);
  color: darkslateblue;
  font-size: 2.3vh;
  font-weight: 600;
  cursor: pointer;
  }
  .seemore-comment-btn-clicked{
  width: 60%;
  padding: 2%;
  border: 1px solid rgba(78, 75, 75, 0.301);
  background-color: rgb(255, 255, 255);
  color: darkslateblue;
  font-size: 2.3vh;
  font-weight: 600;
  cursor:pointer;
  border-top: 5px solid green;
  }
  .seemore-comment-btn:hover{
  border-top: 5px solid green;
  transition: 0.2s;
  }
  .comment-section-container
  {
  margin: 2% 0%;
  width: 60%;
  }
  
  .seemore-card{
  position: relative;
  width: 55%;
  }
  
  .seemare-img-section{
  position: relative;
  width: 100%;
  height: 430px;
  }
  .seemore-event
  {
  margin-top: 5%;
  font-size: 30px;
  color: rgb(4, 4, 65);
  }
  .comment{
  margin: 1% 0%;
  padding: 10px;
  }
  .comment-name
  {
  font-size: 16px;
  color: rgb(67, 38, 172);
  font-weight: bold;
  margin-bottom: 0px;
  }
  .comment-date
  {
  font-size: 10px;
  margin-left: 11%;
  margin-top: -3%;
  color: rgb(100, 97, 97);
  }
  .comment-desc
  {
  font-size: 14px;
  text-align: justify;
  color: rgb(100, 97, 97);
  }
  .date {
  height: 50px;
  width: 60px;
  background-color: #febe10;
  position: absolute;
  right: 0;
  bottom: 0;
  }
  .dat{
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  }
  .comment-input{
  width: 85%;
  margin: 2% 0%;
  padding: 2%;
  }
  .submit-btn{
  margin: 2% 25%;
  width: 30%;
  padding: 2%;
  border: 1px solid #fff;
  background-color: rgba(223, 217, 217, 0.466);
  color: darkslateblue;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  }
    
  /* CSS */
  .button-37 {
    background-color: #13aa52;
    border: 1px solid #13aa52;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-37:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
  }
  .skeleton{
      display: flex;
      flex-direction: row;
      width: 100%;
      column-gap: 60px;
  }
  .top-skeleton{
      margin: 3% -2%;
  }
  .comment-flex{
      display: flex;
      flex-direction: row;
      gap: 10px;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  .seemore-swiper {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper {
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .seemore-img-card {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .seemore-img-card .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .seemore-img-card .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide .seemore-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide .seemore-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .seemore-story{
    width: 100%;
    text-align: justify;
  }
  
  @media (min-width: 768px) {
    .button-37 {
      padding: 10px 30px;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .backoption {
      cursor: pointer;
      width: 18%;
  }
    .comment-section-container {
      margin: 2% 0%;
      width: 100%;
  }

    .seemore-comment-btn {
      width: 80%;
      padding: 2%;
      border: 1px solid #fff;
      background-color: rgba(223, 217, 217, 0.466);
      color: darkslateblue;
      font-size: 2.3vh;
      font-weight: 600;
      cursor: pointer;
  }
    .seemore-story{
      width: 100%;
    }
    .comment-date {
      font-size: 10px;
      margin-left: 18%;
      margin-top: -5%;
      color: rgb(100, 97, 97);
  }
      .seemore-container{
      margin-top: 100%;
          display:block;
          margin: 20% 2%;
  
      }
      .seemore-card{
          position: relative;
          width: 100%;
      }
      .seemore-img
      {
          width: 100%;
          height: 100%;
      }
      .seemare-img-section{
          position: relative;
          width: 100%;
          height: 100%;
      }
      .skeleton{
          display: flex;
          flex-direction: column;
          width: 100%;
          row-gap: 60px;
      }
      .top-skeleton{
          margin: 3% -2%;
      }
  }
  .contain-images{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
    align-items: flex-start;
   }
   .contain-images .main-picture{
     background-color: #fff;
     border-radius: 5px;
     padding: 10px;
   }
   .contain-images .main-picture .pic-disp{
     width: 100%;
     height: 300px;
   }
   .contain-images .picture-list{
  background-color: #fff;
  border-radius: 5px;
  height: 400px;
  overflow-y: scroll;
   }
   .contain-images .picture-list::-webkit-scrollbar{
     width: 3px;
   }
   .contain-images .picture-list::-webkit-scrollbar-track{
    background-color: #ccc;
    border-radius: 20px;
  }
  .contain-images .picture-list::-webkit-scrollbar-thumb{
    background-color: rgba(151, 146, 101, 0.63);
    border-radius: 20px;
  }
  .contain-images .picture-list .pic .pic-disp{
  width: 200px;
  height: 150px;
  border-radius: 5px;
   }
  .contain-images .picture-list .pic{
  display: flex;
  align-items: center;
  gap:15px;
  background-color: #fff;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  }
  
  .contain-images .picture-list .pic:hover{
  background-color: #eee;
    }
  .contain-images .picture-list .pic.active{
  background-color: #d9dbd1a6;
  }
  .shareimg
  { 
    margin-top: 8%;
    cursor: pointer;
  }
  @media (max-width:991px) {
    .skeleton-contain{
      display: flex;
      flex-direction: column;
    }
    .skeleton-col{
      margin-top: 10%;
    }
    .width-height
    {
      width: 320px;
    }
    .contain-images{
      grid-template-columns: 1.5fr 1fr;
      padding: 10px;
    }
    .contain-images .main-picture .pic-disp {
      width: 100%;
      height: 231px;
  }
  .year-wise{
    margin: 0% 30%;
  }
  .social-share-container{
    display: flex;
  flex-direction: row;
  gap:1em;
  font-size: 30px;
  text-align: center;
  }
  }
  @media (max-width:768px) {
    .contain-images{
      grid-template-columns:1fr;
    }
  }