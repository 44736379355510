.trm-container{
    padding:0% 10%;
}
.term-content{
    text-align: justify;
    font-size: 13px;
}
.termmain-title{
    color: black;
    font-size:25px;
    font-weight: 600;
    margin-top: 20px;
    padding: 10px 0px 10px 0px;
}
.term-title{
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    color:#febe10 ;
    font-weight:500;
}   
   .sub-title{
       color: #febe10 ;
       margin-top: 10px;
       font-size:16px;
   }
    .fstpoint{
       margin-left: 35px;
       font-size:13px;
   }
   
