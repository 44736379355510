.button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #f7f7f7 0, #f7f2f3d0 100%);
    border: 0;
    border-radius: 50px 20px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #febe10;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 15px;
    width: 100%;
  }
  
  
  .button-29:focus {
    box-shadow: #fdfdfd 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #e6e7ee 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #febe10 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #fff 0 3px 7px inset;
    transform: translateY(2px);
  }

.button-y {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #febe10 0, #febe10 100%);
    border: 0;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 15px;
  }
  
  .button-y:focus {
    box-shadow: #febe10 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #febe10 0 -3px 0 inset;
  }
  
  .button-y:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #febe10 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-y:active {
    box-shadow: #ffff 0 3px 7px inset;
    transform: translateY(2px);
  }

  /* Arrow */

  
#myBtn{
  position: fixed;
  width:50px;
  height: 50px;
  background-color: #febe10;
  bottom:40px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  right: 20px;
  text-decoration: none;
  text-align: center;
  color: white;
  line-height:20px;
  font-size: 30px;
  z-index: +2;
  border: none;
  border-radius:50%;
  cursor: pointer;
}

@media (max-width: 952px) {
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #f7f7f7 0, #f7f2f3d0 100%);
  border: 0;
  border-radius: 50px 20px;
  box-shadow: rgb(45 35 66 / 40%) 0 2px 4px, rgb(45 35 66 / 30%) 0 7px 13px -3px, rgb(58 65 111 / 50%) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #febe10;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 15px;
  width: 50%;
}
}