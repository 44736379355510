.main-title{
    font-size: 22px;
    font-weight: 700;
    padding: 10px 0px 10px 0px;
}
.privacy-title{
    font-size: 20px;
    padding: 10px 0px 10px 0px;
    color:#febe10 ;
    font-weight:500;

}
.policypoint {
    list-style: disc;
    margin-left: 20px;
}
.pri-container{
    padding: 0% 13%;
}
.privacy-content{
    font-size:15px;
    font-weight: 400;
}
.contain-privacy{
    margin-top: 5%;
}
@media (max-width: 952px) {
    .contain-privacy{
        margin-top: 20%;
    }
}
.privacypoint{
    margin-left: 5%;
    font-size: 15px;
}