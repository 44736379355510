.col-md-12{
    margin-top:95px;
   
}
.aman{
    height: 300px;
    width:240px;
    padding-left:8px ;
    border-left: 2px solid  rgb(248, 242, 242) ;
}
.photocontainer{
    display: flex;
    flex-direction: row;
    width: 98%;
    padding-top: 20px;  
    
}
.founder{
  margin-top:30px;
  font-size: 28px;
  font-weight: 500;
  color: rgb(80, 78, 78);
}
.founder-contain{
    padding: 1% 3% 1% 0%;
}
.photo{
width: 250px;
height: 280px;
}
.descs{
 font-size: 20px;   
}
.image-container img{
    height: 650px;
    width:250px;
    margin-left: 30%;
}
.title{
    margin-top:20px;
    font-weight: 400;
    text-align: center;
    color: dimgrey;

}
.side-title{
    color: #febe10;
    font-size: 20px;
    margin: 1% 0%;
}
.whowehead{
font-weight: 700;
margin: 2% 0%;
}

.contends{
    font-size:15px;
    font-weight:300px;
}
.who-container
{
    padding: 0% 13%;
}
.tab-center
{   
    display: flex;
    justify-content: center;
    margin: 2%;
   
}
td{
    padding: 10px;
}
.tab-img
{
    width: 100%;
    height: 700px;
    border-left: 1px solid #e8ebee;
    border-right: 1px solid #e8ebee;
}
h5{
    margin-left: 1px;
}
@media screen and (max-width: 900px){
    .photocontainer{
        flex-direction: column;
    }
    .ladercontainer{
        flex-direction: column;
    }
    .tab-img
{
    width: 100%;
    height: 500px;
    border-left: 1px solid #e8ebee;
    border-right: 1px solid #e8ebee;
}

}
